import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-parallax-section',
  templateUrl: './parallax-section.component.html',
  styleUrls: ['./parallax-section.component.scss']
})
export class ParallaxSectionComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

  toTheAppStore($e) {
    window.open(environment.appStore);
  }
}
