import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-presentation-section',
  templateUrl: './presentation-section.component.html',
  styleUrls: ['./presentation-section.component.scss']
})
export class PresentationSectionComponent implements OnInit {
  storeLink: string;
  
  constructor() { }

  ngOnInit() {
    this.storeLink = environment.appStore;
  }

}
