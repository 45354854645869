import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  nowDate: number;
  storeLink: string;

  constructor() { 
    this.nowDate = new Date().getFullYear();
  }

  ngOnInit() {
    this.storeLink = environment.appStore;
  }

}
