import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-download-section',
  templateUrl: './download-section.component.html',
  styleUrls: ['./download-section.component.scss']
})
export class DownloadSectionComponent implements OnInit {
  storeLink: string;

  constructor() { }

  ngOnInit() {
    this.storeLink = environment.appStore;
  }

}
